import { IContextPropsModel, IFormPropsModel } from '@/common/utils/withContext';
import { IUserProfileModel } from '@/store/auth/types';

export class ChargingStationFormModel{
	siteArea:string='';
	siteAreaID:string='';
	tariffID?:any=null;
	public?:boolean=false;
	maximumAmps:number=0;
	maximumPower:number=0;
	masterSlave:boolean=false;
	manualConfiguration:boolean=false;
	issuer:boolean=false;
	id:string='';
	excludeFromSmartCharging:boolean=false;
	forceInactive:boolean=false;
	coordinates?: CoordinatesModel;
	chargingStationURL?:any=null;
	chargePoints:any[]=[];
	canGetConnectorQRCode?:boolean=false;
	connectors?:ConnectorsModel[]=[];
	capabilities?:any=null;
	site?:string='';
	url?:any=null;
	canUpdateFirmware?:boolean=false;
	ocppProtocol?:any='';
	ocppVersion?:string='';
	chargePointVendor?:string='';
	chargePointModel?:string='';
	endpoint?:string='';
	currentIPAddress?:string='';
	lastReboot?:string='';
	lastSeen?:string='';
	firmwareVersion?:string='';
	createdOn?:string='';
	chargeBoxSerialNumber?:any=null;
	chargerStatus?:any='';
	checkStatus?:any='';
	tracking?:any;
	notes?:any;
	constructor () {
		this.coordinates = { ...new CoordinatesModel() };
	}
}

export class CoordinatesModel{
	longitude?:any='';
	latitude?:any='';
}
export class ConnectorsModel{
	connectorId?:number=0;
	amperagePerPhase?:number=0;
	amperage?:number=0;
	currentType?:string='';
	numberOfConnectedPhase?:number=0;
	voltage?:number=0;
	type?:string='';
	tariffID?:string='';
	power?:number=0;
	phaseAssignmentToGrid?:any;
	shrink?:boolean=false;
}
    
export class phaseAssignmentToGridModel{
	csPhaseL3?:any=null;
	csPhaseL2?:any=null;
	csPhaseL1?:any=null;
}
  
export interface IEditChargingStationFormPropsModel
  extends IContextPropsModel,
    IFormPropsModel<ChargingStationFormModel> {
  onFormClose: () => void
  chargingStationId: string
  userInfo: IUserProfileModel
  editChargingStationForm?: boolean
  canUpdateChargingStation?: boolean
  siteAreaName?: string
  siteName?: string
  siteId?: string
  limit?: number
  fetchAllChargingStations: () => void
  onStatusSelect?: any
  checkState?: any
  notesTab?: boolean
  setParentStateData?: any
}
  