import { httpClient } from './httpClient';
import { ChargingPlanModel } from './models/chargingPlan';
import { ChargingStationModel, IChargingStationAdvancedTabModel, IChargingStationAvailabilityModel, IChargingStationResetModel, IConnectorConsumptionModel, IDefaultCarCatalogModel, IDefaultCarCatalogResponseModel, IgetSessionTransactionDetailsParam, IRegistrationTokenResponseModal, IStartSessionTransactionParam, UserModel } from './models/chargingStation';
import { IResponseModel } from './models/responseModel';

export const getChargingStations = (params: any) => {
	return httpClient.get<IResponseModel<ChargingStationModel>>(
		'/api/charging-stations',
		params
	);
};

export const getNewChargingStations = (params: any) => {
	return httpClient.get<IResponseModel<any>>(
		'/api/charging-stations/action/upload-excel-file',
		params
	);
};

// export const uploadExcelFile = (formData: any) => {
// 	return httpClient.uploadFile('/api/charging-stations/action/upload-excel-file', formData,formData);
// };

export const uploadExcelFile = (formData: any) => {
	return httpClient.uploadFile('/api/charging-stations/action/upload-excel-file', formData, {
		headers: { 'Content-Type': 'multipart/form-data;' }
	});
};

export const createNewChargingRegistrationToken = (formData: any) => {
	return httpClient.post('/api/charging-stations/action/upload', formData);
};

export const exportNewChargingStation = (params: any) => {
	return httpClient.get('/api/charging-stations/action/upload-export', params);
};

export const getChargingStationStatus = (params: any) => {
	return httpClient.get('/api/charging-stations/notifications/status/by-date-range', params);
};

export const resetChargingStation = (chargingStationId: string, params: IChargingStationResetModel) => {
	return httpClient.put<IResponseModel<ChargingStationModel>>(
		`/api/charging-stations/${chargingStationId}/reset`,
		params
	);
};

export const clearChargingStationCache = (chargingStationId: string) => {
	return httpClient.put<IResponseModel<ChargingStationModel>>(
		`/api/charging-stations/${chargingStationId}/cache/clear`
	);
};

export const setChargingStationAvailability = (chargingStationId: string, params: IChargingStationAvailabilityModel) => {
	return httpClient.put<IResponseModel<ChargingStationModel>>(
		`/api/charging-stations/${chargingStationId}/availability/change`,
		params
	);
};

export const getChargingProfiles = (params: any) => {
	return httpClient.get<IResponseModel<ChargingPlanModel>>(
		'/api/charging-profiles',
		params
	);
};

export const getChargingStationById = (chargingStationId: any, params: any) => {
	return httpClient.get(`/api/charging-stations/${chargingStationId}`, params);
};

export const getRegisterToken= (token: any) => {
	return httpClient.get(`/api/registration-token/${token}/validate`);
};

export const chargingStationUnlockConnector = (chargingStationId: string, connectorId: number) => {
	return httpClient.put(`/api/charging-stations/${chargingStationId}/connectors/${connectorId}/unlock`);
};

export const getChargingPlanById = (
	chargingStationId: any,
	params: IChargingStationAdvancedTabModel
) => {
	return httpClient.put(
		`/api/charging-stations/${chargingStationId}/composite-schedule/get`, params
	);
};

export const getQRCodeRecordsChargingStation = (chargingStationId: any) => {
	return httpClient.getQRCode('/api/charging-stations/qrcode/download?ChargingStationID=' +chargingStationId, {},{},'arraybuffer');
};

export const getOCPPParameters = (chargingStationName: string) => {
	return httpClient.get(`/api/charging-stations/${chargingStationName}/ocpp/parameters`);
};

export const getOCPPAuthozationKey = (chargingStationId: string) => {
  return httpClient.get(
    `/api/charging-stations/get-authorization-key-details/${chargingStationId}`
  )
}

export const getNotes = (id: string) => {
	return httpClient.get(`/api/charging-stations/notes/${id}`);
};

export const postNotes = (id: string,payload:any) => {
	return httpClient.post(`/api/charging-stations/notes/${id}`,payload);
};

export const postOCPPAuthorization = (chargingStationId: string, payload: any) => {
  return httpClient.post(
    `/api/charging-stations/update-authorization-key/${chargingStationId}`,
    payload
  )
}

export const updateOCPPParameters = (chargingStationName: string, payload: any) => {
	return httpClient.put(`/api/charging-stations/${chargingStationName}/configuration`, payload);
};

export const updateOCPPConfiguration = (payload: any) => {
	return httpClient.post('/api/charging-stations/ocpp/parameters', payload);
};

export const deleteChargingStations = (chargingStationId: any) => {
	return httpClient.delete('/api/charging-stations/'+chargingStationId);
};

export const exportChargingStations = (params: any) => {
	return httpClient.get('/api/charging-stations/action/export', params);
};

export const getConnectorTransactions = (chargingStationId: any, params: any) => {
	return httpClient.get(
		`/api/charging-stations/${chargingStationId}/transactions?`,
		params
	);
};

export const getLastTransaction = (chargingStationID: string, connectorID: number) => {
	const params: { [param: string]: string } = {};
	params['ConnectorID'] = connectorID.toString();
	params['Limit'] = '1';
	params['Skip'] = '0';
	params['WithTag'] = 'true';
	params['SortFields'] = '-timestamp';
	
	return httpClient.get(`/api/charging-stations/${chargingStationID}/transactions`, params);
};

export const getTransaction = (id: number) => {
	const params: { [param: string]: string } = {};
	params['WithUser'] = 'true';
	params['WithTag'] = 'true';
	params['WithCar'] = 'true';
	
	if (!id) {
		return;
	}
	
	return httpClient.get(`/api/transactions/${id}`, params);
};

export const getConnectorUserDetails = (transactionId: any, params: any) => {
	return httpClient.get(`/api/transactions/${transactionId}`, params);
};

export const downloadChargingStationQrCode = (params: any) => {
	return httpClient.getQRCode('/api/charging-stations/qrcode/download', params, {}, 'arraybuffer');
};

export const generateChargingStationQRCode = (chargingStationId: any, connectorId: any) => {
	return httpClient.get(`/api/charging-stations/${chargingStationId}/connectors/${connectorId}/qrcode/generate`);
};

export const navigateChargingStationQRCode = (chargingStationId: any) => {
	return httpClient.get(`/api/charging-stations/qrcode/url?ChargingStationID=${chargingStationId}`);
};

export const getChargingProfilesById = (params: any) => {
	return httpClient.get(`/api/charging-profiles?ChargingStationID=${params.chargingStationId}&Limit=${params.defaultPageSize}&TransactionID=${params.transactionID}&ConnectorID=${params.connectorID}`);
};

export const updateChargingStationForm = (chargingStationId: any, formModel: any) => {
	return httpClient.put(`/api/charging-stations/${chargingStationId}/parameters`,formModel);
};

export const updateChargingProfile = (chargingProfile: any) => {
	return httpClient.put(`/api/charging-profiles/${chargingProfile.id}`, chargingProfile);
};

export const createChargingProfile = (chargingProfile: any) => {
	return httpClient.post('/api/charging-profiles', chargingProfile);
};

export const deleteChargingProfile = (id: string) => {
	return httpClient.delete(`/api/charging-profiles/${id}`);
};

export const triggerSmartCharging = (siteAreaID: string) => {
	const params: { [param: string]: string } = {};
	params['SiteAreaID'] = siteAreaID;
	return httpClient.get('/api/charging-stations/smartcharging/trigger', params);
};

export const updateChargingStationUrl = (chargingStationId: string,locationURL:string) => {
	const date = new Date().toISOString();
	const body = (
		`{
        "args": {
          "location": "${locationURL}",
          "retries": 0,
          "retrieveDate": "${date}",
          "retryInterval": 0
        }
      }`
	);
	return httpClient.put(`/api/charging-stations/${chargingStationId}/firmware/update`,body);
};

export const getConnectorConsumption = (currentTransactionID: number) => {
	return httpClient.get<IConnectorConsumptionModel[]>(`/api/transactions/${currentTransactionID}/consumptions`);
};

export const getConnectorAllConsumption = (currentTransactionID: number) => {
	return httpClient.get<IConnectorConsumptionModel[]>(`/api/transactions/${currentTransactionID}/consumptions?LoadAllConsumptions=true`);
};

export const getUserImage = (userId: any) => {
	return httpClient.getImage<any>(`/api/users/${userId}/image`);
};

export const getCarCatalogImage = (carCatalogId: any, params: any) => {
	return httpClient.get<any>(`/api/car-catalogs/${carCatalogId}/images`, params);
};

export const getDefaultCarCatalog = (userId: any, params: IDefaultCarCatalogModel) => {
	return httpClient.get<IDefaultCarCatalogResponseModel>(`/api/users/${userId}/default-car-tag`, params);
};

export const getUsers = (params: any) => {
	return httpClient.get<UserModel>('/api/users', params);
};

export const getTags = (params: any) => {
	return httpClient.get('/api/tags', params);
};

export const getCars = (params: any) => {
	return httpClient.get('/api/cars', params);
};

export const startSessionTransaction = (params: IStartSessionTransactionParam) => {
	return httpClient.put('/api/transactions/start', params);
};

export const getSessionTransactionDetails = (transactionId: string, params: IgetSessionTransactionDetailsParam) => {
	return httpClient.get(`/api/transactions/${transactionId}`, params);
};

export const stopSessionTransaction = (transactionId: string) => {
	return httpClient.put(`/api/transactions/${transactionId}/stop`);
};

export const getChargingStationsInError = (params: any) => {
	return httpClient.get('/api/charging-stations/status/in-error', params);
};

export const getRegistrationTokens = (params: any) => {
	return httpClient.get<IRegistrationTokenResponseModal[]>('/api/registration-tokens', params);
};

export const getUsersInError = (params: any) => {
	return httpClient.get('/api/users/status/in-error', params);
};

export const deleteUserById = (userId: any) => {
	return httpClient.delete(`/api/users/${userId}`);
};

export const createChargingRegistrationToken = (formData: any) => {
	return httpClient.post('/api/registration-tokens', formData);
};

export const getChargingRegistrationTokenById = (id:any) => {
	return httpClient.get('/api/registration-tokens/'+id);
};

export const updateChargingRegistrationToken = (formData: any,id:any) => {
	return httpClient.put('/api/registration-tokens/'+id,formData);
};

export const deleteChargingRegistrationToken = (tokenId: any) => {
	return httpClient.delete(`/api/registration-tokens/${tokenId}`);
};

export const registerChargerInstallation = (formData: any) => {
	return httpClient.post(`/api/charging-stations/action/update-registered-charger-installed`, formData);
};