import { findRequiredFields } from '@/common/utils/validationHelper';
import * as schema from 'yup';
import { DimensionFormModel, PriceFormModel, RestrictionFormModel, StaticRestrictionFormModel } from '../models/price';
import { isBefore, isAfter, isEqual } from 'date-fns';

const emptyStringHandler = (value: any, originalValue: any) => originalValue === '' ? undefined : value;

export const formSchema = schema.object<Partial<Record<keyof PriceFormModel, schema.AnySchema>>>({
	name: schema.string().required('general.mandatory_field'),
	description: schema.string().required('general.mandatory_field'),
	dimensions: schema.object<Partial<Record<keyof DimensionFormModel, schema.AnySchema>>>({
		flatFee: schema.object({
			active: schema.boolean(),
			price: schema
				.string()
				.default('0.00') // Set default value to "0.00"
				.when('active', {
					is: true,
					then: schema
						.string()
						.required('general.mandatory_field')
						.matches(/^\d+(\.\d{2})$/, 'Must have exactly two decimal places.')
						.test('max-value', 'Maximum allowed is $30', (value) => parseFloat(value || '0') <= 30)
						.test('min-value', 'Minimum allowed is $0', (value) => parseFloat(value || '0') >= 0),
					otherwise: schema.string().notRequired(),
				}),
		}),
		energy: schema.object({
			active: schema.boolean(),
			stepSizeEnabled: schema.boolean(),
			price: schema.string().when('active', {
				is: true,
				then: schema
					.string()
					.required('general.mandatory_field')
					.matches(/^\d+(\.\d{2})$/, 'Must have exactly two decimal places.')
					.test('max-value', 'Maximum allowed is $2/kWh', (value) => parseFloat(value || '0') <= 2)
					.test('min-value', 'Minimum allowed is $0/kWh', (value) => parseFloat(value || '0') >= 0),
				otherwise: schema.string().notRequired(),
			}),
			stepSize: schema.string().when(['active', 'stepSizeEnabled'], {
				is: (active: boolean, stepSizeEnabled: boolean) => active && stepSizeEnabled,
				then: schema
					.string()
					.required('general.mandatory_field')
					.matches(/^[0-9\b]+$/, 'general.error_number_pattern'),
				otherwise: schema.string().notRequired(),
			}),
		}),
		chargingTime: schema.object({
			active: schema.boolean(),
			stepSizeEnabled: schema.boolean(),
			price: schema.string().when('active', {
				is: true,
				then: schema
					.string()
					.required('general.mandatory_field')
					.matches(/^\d+(\.\d{2})$/, 'Must have exactly two decimal places.')
					.test('max-value', 'Maximum allowed is $10/hr', (value) => parseFloat(value || '0') <= 10)
					.test('min-value', 'Minimum allowed is $0/hr', (value) => parseFloat(value || '0') >= 0),
				otherwise: schema.string().notRequired(),
			}),
			stepSize: schema.string().when(['active', 'stepSizeEnabled'], {
				is: (active: boolean, stepSizeEnabled: boolean) => active && stepSizeEnabled,
				then: schema
					.string()
					.required('general.mandatory_field')
					.matches(/^[0-9\b]+$/, 'general.error_number_pattern'),
				otherwise: schema.string().notRequired(),
			}),
		}),
		parkingTime: schema.object({
			active: schema.boolean(),
			stepSizeEnabled: schema.boolean(),
			price: schema.string().when('active', {
				is: true,
				then: schema
					.string()
					.required('general.mandatory_field')
					.matches(/^\d+(\.\d{2})$/, 'Must have exactly two decimal places.')
					.test('max-value', 'Maximum allowed is $10/hr', (value) => parseFloat(value || '0') <= 10)
					.test('min-value', 'Minimum allowed is $0/hr', (value) => parseFloat(value || '0') >= 0),
				otherwise: schema.string().notRequired(),
			}),
			stepSize: schema.string().when(['active', 'stepSizeEnabled'], {
				is: (active: boolean, stepSizeEnabled: boolean) => active && stepSizeEnabled,
				then: schema
					.string()
					.required('general.mandatory_field')
					.matches(/^[0-9\b]+$/, 'general.error_number_pattern'),
				otherwise: schema.string().notRequired(),
			}),
		}),
	}),
	staticRestrictions: schema.object<Partial<Record<keyof StaticRestrictionFormModel, schema.AnySchema>>>({
		connectorPowerEnabled: schema.boolean(),
		connectorPowerkW: schema.string().when('connectorPowerEnabled', {
			is: true,
			then: schema.string().required('general.mandatory_field').matches(/^\s*(\d+|(\d*(\.\d*)))([eE]?\d+)?\s*$/, 'general.error_number_pattern'),
			otherwise: schema.string().notRequired()
		}),
		validFrom: schema.string().notRequired().transform((value, originalValue) => {
			if (originalValue != 'Invalid Date' && originalValue != '' && originalValue != null) {
				return new Date(originalValue).toJSON();
			}
			else if (originalValue == 'Invalid Date') {
				return 'general.invalid_date';
			}
			else if (originalValue == null) {
				return '';
			}
		}).test({
			name: 'validFromLessThanValidTo',
			message: '',
			test: (value, context) => {
				if (!value || !context.parent.validTo) {
					return true;
				}
				const dateFrom = new Date(value);
				const dateTo = new Date(context.parent.validTo);
				return isBefore(dateFrom, dateTo) || isEqual(dateFrom, dateTo);
			}
		}),
		validTo: schema.string().notRequired().transform((value, originalValue) => {
			if (originalValue != 'Invalid Date' && originalValue != '' && originalValue != null) {
				return new Date(originalValue).toJSON();
			}
			else if (originalValue == 'Invalid Date') {
				return 'general.invalid_date';
			}
			else if (originalValue == null) {
				return '';
			}
		}).test({
			name: 'validToGreaterThanValidFrom',
			message: 'settings.pricing.end_date_error',
			test: (value, context) => {
				if (!value || !context.parent.validFrom) {
					return true;
				}
				const dateTo = new Date(value);
				const dateFrom = new Date(context.parent.validFrom);
				return isAfter(dateTo, dateFrom) || isEqual(dateFrom, dateTo);
			}
		})
	}),
	restrictions: schema.object<Partial<Record<keyof RestrictionFormModel, schema.AnySchema>>>({
		daysOfWeekEnabled: schema.boolean().test({
			name: 'daysOfWeekEnabled',
			message: '',
			test: (value, context) => {
				if (value) {
					if (context.parent?.daysOfWeek?.length == 0) {
						return false;
					}
					else { return true; }
				}
				else {
					return true;
				}
			}
		}),
		daysOfWeek: schema.array().of(schema.string().test({
			name: 'daysOfWeek',
			message: '',
			test: (value, context) => {
				if (value) {
					if (context.parent?.daysOfWeek?.length == 0) {
						return false;
					}
					else { return true; }
				}
				else {
					return true;
				}
			}
		})

		),
		minDurationSecsEnabled: schema.boolean(),
		minDurationSecs: schema.number().integer('general.error_number_pattern').transform(emptyStringHandler).when('minDurationSecsEnabled', {
			is: true,
			then: schema.number().transform(emptyStringHandler)
				.required('general.mandatory_field').typeError('general.error_number_pattern').test({
					name: 'minDurationCheck',
					message: '',
					test: ((value, context) => {
						if (!value || isNaN(value) || isNaN(context.parent.maxDurationSecs) || !context.parent.maxDurationSecsEnabled) {
							return true;
						} else {
							return value < context.parent.maxDurationSecs;
						}
					})
				}),
			otherwise: schema.number().transform(emptyStringHandler).notRequired()
		}),
		maxDurationSecsEnabled: schema.boolean(),
		maxDurationSecs: schema.number().integer('general.error_number_pattern').transform(emptyStringHandler).when('maxDurationSecsEnabled', {
			is: true,
			then: schema.number().transform(emptyStringHandler)
				.required('general.mandatory_field').typeError('general.error_number_pattern').test({
					name: 'maxDurationCheck',
					message: 'settings.pricing.pricing_max_duration_error',
					test: ((value, context) => {
						if (!value || isNaN(value) || isNaN(context.parent.minDurationSecs) || !context.parent.minDurationSecsEnabled) {
							return true;
						} else {
							return value > context.parent.minDurationSecs;
						}
					})
				}),
			otherwise: schema.number().transform(emptyStringHandler).notRequired()
		}),
		minEnergyKWhEnabled: schema.boolean(),
		minEnergyKWh: schema.number().integer('general.error_number_pattern').transform(emptyStringHandler).when('minEnergyKWhEnabled', {
			is: true,
			then: schema.number().transform(emptyStringHandler)
				.required('general.mandatory_field').typeError('general.error_number_pattern').test({
					name: 'minEnergyKWhCheck',
					message: '',
					test: ((value, context) => {
						if (!value || isNaN(value) || isNaN(context.parent.maxEnergyKWh) || !context.parent.maxEnergyKWhEnabled) {
							return true;
						} else {
							return value < context.parent.maxEnergyKWh;
						}
					})
				}),
			otherwise: schema.number().transform(emptyStringHandler).notRequired()
		}),
		maxEnergyKWhEnabled: schema.boolean(),
		maxEnergyKWh: schema.number().integer('general.error_number_pattern').transform(emptyStringHandler).when('maxEnergyKWhEnabled', {
			is: true,
			then: schema.number().transform(emptyStringHandler)
				.required('general.mandatory_field').typeError('general.error_number_pattern').test({
					name: 'maxEnergyKWhCheck',
					message: 'settings.pricing.pricing_max_energy_error',
					test: ((value, context) => {
						if (!value || isNaN(value) || isNaN(context.parent.minEnergyKWh) || !context.parent.minEnergyKWhEnabled) {
							return true;
						} else {
							return value > context.parent.minEnergyKWh;
						}
					})
				}),
			otherwise: schema.number().transform(emptyStringHandler).notRequired()
		}),
		timeRangeEnabled: schema.boolean(),
		timeFrom: schema.string().when('timeRangeEnabled', {
			is: true,
			then: schema.string().required('general.mandatory_field').matches(/^$|\d+$/, 'general.error_number_pattern').not([schema.ref('timeTo')], ''),
			otherwise: schema.string().notRequired()
		}),
		timeTo: schema.string().when('timeRangeEnabled', {
			is: true,
			then: schema.string().required('general.mandatory_field').matches(/^$|\d+$/, 'general.error_number_pattern').not([schema.ref('timeFrom')], 'settings.pricing.pricing_definition_time_range_error'),
			otherwise: schema.string().notRequired()
		}),
	})
});

export const requiredFields = findRequiredFields('', formSchema.describe().fields);